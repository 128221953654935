@import "../../../stylemain/descriptions";

.project-card-gallery {
  margin-bottom: 70px;

  @include after($p1700) {
    margin-bottom: 130px;
  }

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 65px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 50px;

    @include after($p580) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include after($p1024) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: 16px;
      column-gap: 16px;
    }

    @include after($p1700) {
      margin-bottom: 100px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .button.-size-l {
    width: 100%;

    @include after($p768) {
      height: 77px;
      line-height: 77px;
    }

    @include after($p1700) {
      line-height: 180px;
      height: 180px;
    }
  }
}
