@import "./stylemain/reset";
@import "./stylemain/descriptions";
@import "./stylemain/fonts";

html,
body {
  width: 100%;
  height: 100%;

  &.-overflow-hidden {
    overflow: hidden;
  }
}

body {
  font-family: $font-family-Manrope;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background: #ffffff;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @include after($p1700) {
    font-size: 24px;
    line-height: 33px;
  }
}

.wrap {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @include after($p1024) {
    padding-left: 0;
    padding-right: 0;
    max-width: 960px;
  }

  @include after($p1700) {
    max-width: 1600px;
  }
}

.main {
  padding-top: 40px;
  margin-bottom: 70px;

  @include after($p1700) {
    padding-top: 50px;
    margin-bottom: 80px;
  }
}

.scrollMarginTop {
  scroll-margin-top: 30px;
}
