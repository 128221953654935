@import "../../stylemain/descriptions";

.headline {
  color: #0c0d2b;
  font-weight: 800;
  font-size: calc(28px + 20 * ((100vw - 320px) / 1600));
  line-height: calc(30px + 20 * ((100vw - 320px) / 1600));
  letter-spacing: -0.02em;
  margin-bottom: 24px;

  @include after($p1700) {
    font-size: 48px;
    line-height: 50px;
  }
}
