@import "../../stylemain/descriptions";

.projects {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;

    @include after($p580) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }

    @include after($p768) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
      row-gap: 24px;
    }

    @include after($p1024) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 30px;
      row-gap: 30px;
    }

    @include after($p1700) {
      column-gap: 75px;
      row-gap: 75px;
    }
  }

  &__item {
    padding: 20px;
    background: #000000;
    border-radius: 10px;
    max-width: 340px;
    margin: 0 auto;

    @include after($p1700) {
      padding: 40px 40px 30px 40px;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 40px;

    @include after($p1700) {
      margin-bottom: 60px;
    }
  }

  &__itemtitle {
    color: #e7e7e7;
    font-weight: 800;
    font-size: calc(14px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(22px + 11 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &__iteminfo {
    font-weight: 300;
    font-size: calc(11px + 7 * ((100vw - 320px) / 1600));
    line-height: calc(17px + 3 * ((100vw - 320px) / 1600));
    color: #a6a6a6;
    margin-top: 20px;

    @include after($p1700) {
      display: flex;
      justify-content: space-between;
    }

    @include after($p1700) {
      margin-top: 30px;
      font-size: 16px;
      line-height: 22px;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 12px;

        @include after($p1700) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__itemvalue {
    color: #ffffff;
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(20px + 7 * ((100vw - 320px) / 1600));
    margin-top: 3px;

    @include after($p1700) {
      font-size: 20px;
      line-height: 27px;
    }

    &.-pice {
      font-weight: 700;
      color: #52f6fc;
      text-transform: uppercase;
    }
  }
}
