@import "../../../../stylemain/descriptions";

.form-subscribe {
  max-width: 350px;
  margin: 0 auto;

  @include after($p768) {
    max-width: 52%;
    text-align: left;
    margin: 0;
  }

  @include after($p1024) {
    max-width: 470px;
  }

  @include after($p1700) {
    max-width: 784px;
  }

  &__text {
    color: #75758a;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;

    @include after($p768) {
      text-align: left;
      margin-bottom: 32px;
    }

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 48px;
    }
  }

  &__content {
    @include after($p768) {
      display: flex;
    }
  }

  &__fieldbox {
    @include after($p768) {
      flex-grow: 1;
      height: auto;
    }
  }

  &__field {
    height: 48px;
    width: 100%;
    display: inline-block;
    font-size: 14px;
    font-family: $font-family-Manrope;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
    outline: 0;
    text-decoration: none;
    white-space: nowrap;
    border: 1px solid #646474;
    background: #1f203d;
    border-radius: 24px;

    @include placeholder(#75758a);

    &:focus {
      @include placeholder(transparent);
    }

    @include after($p1700) {
      padding: 0 40px;
      font-size: 24px;
      border-radius: 40px;
      border-width: 2px;
      height: 80px;
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 12px;
    line-height: 14px;
    color: #dc143c;
  }

  &__failed {
    text-align: center;
    color: #dc143c;
    margin-bottom: 8px;
  }

  .button {
    width: 100%;
    margin-top: 16px;
    font-size: 14px;
    line-height: 48px;
    height: 48px;
    padding: 0 40px;
    border-radius: 24px;

    @include after($p768) {
      width: auto;
      margin-left: 8px;
      margin-top: 0;
    }

    @include after($p1700) {
      font-size: 20px;
      line-height: 80px;
      height: 80px;
      padding: 0 70px;
      border-radius: 40px;
    }
  }
}
