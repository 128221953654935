@import "../../../stylemain/descriptions";

.payment-finish {
  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p1700) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 77px;
    }
  }

  &__text {
    max-width: 640px;
    color: #7c7c7c;
    font-size: calc(14px + 6 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 9 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .button {
    margin-top: 60px;

    @include after($p1700) {
      margin-top: 90px;
    }
  }
}
