@import "../../stylemain/descriptions";

.checkbox {
  &__body {
    position: relative;

    &.-error {
      .checkbox__icon {
        border-color: #dc143c;
      }

      .checkbox__iconcheck {
        fill: #dc143c;
      }
    }
  }

  &__input {
    display: none;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    outline: none;
    flex-shrink: 0;
    border: 2px solid #5e07dd;
    border-radius: 3px;

    input:disabled + & {
      cursor: not-allowed;
      border-color: #a0a0a033;

      .checkbox__iconcheck {
        fill: #a0a0a033;
      }
    }

    input:checked + & {
      .checkbox__iconcheck {
        opacity: 1;
      }
    }
  }

  &__iconcheck {
    display: block;
    fill: #5e07dd;
    opacity: 0;
  }

  &__label {
    padding-left: 28px;
  }

  &__message {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 4px;
  }
}
