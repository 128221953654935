@import "../../../stylemain/descriptions";

.project-card-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 54px;

  @include after($p768) {
    flex-wrap: nowrap;
  }

  @include after($p1700) {
    margin-bottom: 90px;
  }

  &__middle {
    display: flex;
    align-items: flex-start;
  }

  &__favourites {
    display: block;
    box-sizing: border-box;
    outline: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0 none;
    background: none;
    cursor: pointer;
    margin: 12px 0 0 40px;
  }

  &__back {
    display: block;
    box-sizing: border-box;
    outline: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0 none;
    background: none;
    cursor: pointer;
    margin: 0 32px 24px 0;

    @include after($p768) {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  .headline {
    font-size: calc(38px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(40px + 10 * ((100vw - 320px) / 1600));

    @include after($p768) {
      font-size: 48px;
      line-height: 50px;
    }
  }
}
