@import "../../../stylemain/descriptions";

.payment-calc {
  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p1700) {
      font-size: 38px;
      line-height: 40px;
    }
  }

  &__rows {
    color: #000000;
    font-weight: 500;
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(20px + 6 * ((100vw - 320px) / 1600));
    margin-bottom: 40px;

    @include after($p1024) {
      display: grid;
      grid-template-columns: 40% 60%;
      row-gap: 47px;
      align-items: center;
      margin-bottom: 60px;
    }

    @include after($p1700) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__label {
    font-weight: 700;
    margin-bottom: 12px;

    @include after($p1024) {
      margin-bottom: 0;
    }
  }

  &__value {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include after($p1024) {
        margin-bottom: 0;
      }
    }

    &.-square {
      display: flex;
      align-items: center;
    }
  }

  &__fieldsquare {
    width: 120px;
    margin-right: 20px;
  }

  &__square {
    display: inline-flex;
    align-items: center;

    @include after($p1024) {
      width: 90%;
      justify-content: space-between;
    }
  }

  .action-plus-minus {
    padding-left: 30px;

    @include after($p1024) {
      padding-left: 0;
    }
  }

  .notice {
    margin-bottom: 30px;

    @include after($p1700) {
      margin-bottom: 45px;
    }
  }
}
