@import "../../stylemain/descriptions";

.info-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000;

  &__body {
    font-size: 12px;
    line-height: 14px;
    padding: 20px;
    color: #0c0d2b;

    @include after($p768) {
      display: flex;
      align-items: center;
    }

    @include after($p1024) {
      display: flex;
      font-size: 16px;
      line-height: 18px;
      padding: 25px 0;
    }
  }

  &__button {
    margin-top: 20px;

    @include after($p768) {
      margin-top: 0;
      margin-left: 20px;
    }

    .button {
      width: 164px;
      padding: 0;
    }
  }
}
