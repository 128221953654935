@import "../../../stylemain/descriptions";

.header-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 14px;

    @include after($p1700) {
      padding-top: 24px;
    }
  }

  &__logo {
    position: relative;
    z-index: 10;
    display: block;
    width: 152px;
    height: auto;

    @include after($p1700) {
      width: auto;
    }
  }

  &__menu {
    padding-top: 80px;

    @include after($p768) {
      display: flex;
      align-items: center;
      padding-top: 0;
    }
  }

  &__menuitem {
    text-align: center;

    @include after($p768) {
      text-align: left;
    }

    &:not(:last-child) {
      margin-bottom: 18px;

      @include after($p768) {
        margin-right: 20px;
        margin-bottom: 0;
      }

      @include after($p1700) {
        margin-right: 34px;
      }
    }

    button {
      display: inline-block;
      color: #ffffff;
      font-size: 20px;
      line-height: 22px;
      font-weight: 700;
      text-decoration: none;
      font-family: $font-family-Manrope;
      padding: 0;
      outline: 0;
      cursor: pointer;
      border: 0 none;
      background: none;

      @include after($p768) {
        font-size: 14px;
        line-height: 18px;
      }

      @include after($p1024) {
        font-size: 16px;
        line-height: 22px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
