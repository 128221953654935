@import "../../../stylemain/descriptions";

.how-works {
  margin-bottom: 100px;

  @include after($p768) {
    margin-bottom: 114px;
  }

  @include after($p1700) {
    margin-bottom: 190px;
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    @include after($p1700) {
      font-size: 64px;
      line-height: 67px;
      margin-bottom: 67px;
    }
  }

  &__list {
    margin-bottom: 40px;

    @include after($p768) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 63px;
    }

    @include after($p1700) {
      margin-bottom: 105px;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 20px;
    padding: 23px 24px 26px 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    margin-bottom: 20px;

    @include after($p768) {
      width: calc(33% - 10px);
      margin-bottom: 0;
    }

    @include after($p1024) {
      width: calc(33% - 21px);
    }

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
      padding: 38px 40px 44px 40px;
      width: calc(33% - 35px);
      border-radius: 40px;
    }

    b {
      font-weight: 800;
    }
  }

  &__itemtitle {
    color: #52f6fc;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    margin-bottom: 29px;

    @include after($p1700) {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 48px;
    }
  }

  &__info {
    @include after($p768) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 59px;
    }

    @include after($p1700) {
      margin-bottom: 98px;
    }
  }

  &__infotitle {
    color: #fa1af7;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-bottom: 16px;

    @include after($p768) {
      font-size: 24px;
      line-height: 26px;
      padding-right: 21px;
      width: calc(33% + 10px);
      margin-bottom: 0;
    }

    @include after($p1024) {
      width: calc(33% - 21px);
    }

    @include after($p1700) {
      padding-right: 35px;
      width: calc(33% + 35px);
      font-size: 40px;
      line-height: 42px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p768) {
      font-size: 22px;
      line-height: 24px;
      padding-left: 29px;
      width: calc(66% + 10px);
      margin-bottom: 0;
    }

    @include after($p1024) {
      width: calc(66% + 21px);
    }

    @include after($p1700) {
      padding-left: 40px;
      width: calc(66% + 35px);
      font-size: 36px;
      line-height: 38px;
    }

    b {
      font-weight: 700;
    }
  }
}
