@import "../../stylemain/descriptions";

.copy-text {
  display: block;
  box-sizing: border-box;
  outline: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;
  border: 0 none;
  background: none;
  cursor: pointer;
}
