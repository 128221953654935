@import "../../stylemain/descriptions";

.message {
  &.-error {
    color: #dc143c;
  }

  &.-info {
    color: inherit;
  }

  &.-success {
    color: #008000;
  }
}
