@import "../../stylemain/descriptions";

.footer-links {
  text-align: center;
  margin-top: 10px;

  @include after($p768) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-top: 0;
  }

  &__item {
    &:not(:last-child) {
      @include before($p768) {
        margin-bottom: 5px;
      }
    }

    &:not(:first-child) {
      @include after($p768) {
        margin-left: 5px;

        &:before {
          content: "|";
          color: #75758a;
          margin-right: 5px;
        }
      }
    }
  }

  &.-admin {
    color: #75758a;

    @include after($p768) {
      justify-content: center;
    }

    @include after($p1024) {
      justify-content: flex-start;
    }

    a {
      color: inherit;
      text-decoration: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
