@import "../../stylemain/descriptions";

.footer {
  &__body {
    font-size: 11px;
    line-height: 17px;
    padding-bottom: 20px;

    @include after($p1024) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
      height: 80px;
    }

    @include after($p1700) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__info {
    text-align: center;
    margin-bottom: 20px;

    @include after($p1024) {
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__logo {
    margin-bottom: 16px;

    @include after($p1024) {
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include after($p1700) {
      margin-right: 40px;
    }
  }

  &__support {
    font-weight: 700;
    color: #666666;

    a {
      position: relative;
      display: inline-block;
      color: inherit;
      text-decoration: none;
      margin-left: 20px;

      @include after($p1700) {
        margin-left: 40px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &:after {
            display: none;
          }
        }
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background: #5e07dd;
      }
    }
  }
}
