//
$font-family-Manrope: "Manrope", sans-serif;
$font-family-Rubik: "Rubik", sans-serif;

//paths
//$img: "../i";
//$fonts: "../fonts";

//points
$p480: 480px;
$p580: 580px;
$p768: 768px;
$p1024: 1024px;
$p1700: 1700px;

@mixin before($point) {
  @media (max-width: ($point - 1)) {
    @content;
  }
}

@mixin between($point1, $point2) {
  @media (min-width: $point1) and (max-width: ($point2 - 1)) {
    @content;
  }
}

@mixin after($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}
