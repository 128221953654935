@import "../../../stylemain/descriptions";

.payment-wallet {
  max-width: 400px;
  padding-top: calc(24px + 30 * ((100vw - 320px) / 1600));
  padding-bottom: calc(24px + 36 * ((100vw - 320px) / 1600));
  padding-left: calc(24px + 40 * ((100vw - 320px) / 1600));
  padding-right: calc(24px + 40 * ((100vw - 320px) / 1600));
  background: #ececec;
  border-radius: 30px;
  margin-top: 60px;

  @include after($p768) {
    margin-top: 0;
    max-width: inherit;
  }

  @include after($p1700) {
    padding: 54px 64px 60px 64px;
  }

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p1700) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 54px;
    }
  }

  &__balance {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 500;
    color: #3f3f3f;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;

    @include after($p1700) {
      margin-bottom: 54px;
    }

    b {
      font-weight: 700;
    }
  }

  &__support {
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    text-decoration: none;
    text-align: left;
    font-family: $font-family-Manrope;
    padding: 0;
    outline: 0;
    cursor: pointer;
    border: 0 none;
    background: none;
    margin-top: 30px;

    @include after($p1700) {
      margin-top: 24px;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background: #5e07dd;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:after {
          display: none;
        }
      }
    }
  }
}
