@import "../../../stylemain/descriptions";

.benefit {
  margin-bottom: 100px;

  @include after($p768) {
    margin-bottom: 96px;
  }

  @include after($p1700) {
    margin-bottom: 132px;
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 50px;
    }

    @include after($p1700) {
      font-size: 64px;
      line-height: 67px;
      margin-bottom: 83px;
    }
  }

  &__content {
    max-width: 500px;
    margin: 0 auto;

    @include after($p768) {
      display: flex;
      justify-content: space-between;
      max-width: inherit;
      margin: 0;
    }
  }

  &__col {
    @include after($p768) {
      width: calc(50% - 29px);
    }

    @include after($p1700) {
      width: calc(50% - 48px);
    }

    &:first-child {
      .benefit__item {
        &:nth-child(1) {
          &:before {
            content: "1";
          }
        }

        &:nth-child(2) {
          &:before {
            content: "2";

            @include after($p768) {
              content: "3";
            }
          }
        }
      }
    }

    &:last-child {
      .benefit__item {
        &:nth-child(1) {
          &:before {
            content: "3";

            @include after($p768) {
              content: "2";
            }
          }
        }

        &:nth-child(2) {
          &:before {
            content: "4";
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    margin-bottom: 36px;

    @include after($p1700) {
      margin-bottom: 60px;
    }

    &:before {
      position: absolute;
      top: 50%;
      width: 15%;
      transform: translateY(-50%);
      right: calc(85% - 0.5vw);
      text-align: right;
      font-family: $font-family-Rubik;
      font-weight: 800;
      font-size: 120px;
      line-height: 120px;
      letter-spacing: -0.02em;

      @include after($p768) {
        top: 0;
        transform: none;
      }

      @include after($p1024) {
        width: 17%;
        right: 84%;
        font-size: 201px;
        line-height: 202px;
      }

      @include after($p1700) {
        font-size: 335px;
        line-height: 337px;
      }
    }

    &.-color1 {
      .benefit__text {
        background: #5e07dd;
      }

      &:before {
        color: #2e0866;
      }
    }

    &.-color2 {
      .benefit__text {
        background: #bd03fe;
      }

      &:before {
        color: #380549;
      }
    }
  }

  &__text {
    position: relative;
    padding: 20px;
    color: #ffffff;
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    margin-left: 15%;
    border-radius: 24px;

    @include after($p768) {
      padding: 24px;
      font-size: 26px;
      line-height: 28px;
    }

    @include after($p1024) {
      padding: 36px 40px 36px 40px;
      font-size: 38px;
      line-height: 40px;
      margin-left: 17%;
    }

    @include after($p1700) {
      font-size: 64px;
      line-height: 67px;
      padding: 60px 65px 60px 65px;
      border-radius: 40px;
    }
  }
}
