@import "../../../stylemain/descriptions";

.project-card-cover {
  margin-top: 100px;
  margin-bottom: 100px;

  @include after($p1700) {
    margin-top: 154px;
    margin-bottom: 154px;
  }

  &__img {
    display: block;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
