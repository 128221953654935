body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
span {
  border: none;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

abbr,
acronym {
  border: none;
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-size: 0.65em;
}

sub {
  top: 0.4em;
}

small,
.small {
  font-size: 0.86em;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
main,
time {
  display: block;
}

* {
  box-sizing: border-box;
}

input {
  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}

textarea {
  -webkit-appearance: none;
}
