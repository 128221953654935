@import "../../../stylemain/descriptions";

.about {
  padding-top: 50px;
  margin-bottom: 100px;

  @include after($p768) {
    padding-top: 120px;
    margin-bottom: 122px;
  }

  @include after($p1700) {
    padding-top: 200px;
    margin-bottom: 203px;
  }

  &__item {
    max-width: 500px;
    margin: 0 auto 40px auto;

    @include after($p768) {
      display: flex;
      max-width: inherit;
    }

    @include after($p1024) {
      width: 83%;
    }

    @include after($p1700) {
      margin-bottom: 66px;
    }

    &.-invert {
      flex-direction: row-reverse;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 20px;

    @include after($p768) {
      padding: 0 20px;
      font-size: 19px;
      line-height: 26px;
      width: 50%;
    }

    @include after($p1700) {
      padding: 0 40px;
      font-size: 32px;
      line-height: 44px;
    }

    b {
      font-weight: 700;
    }
  }

  &__img {
    position: relative;
    height: 250px;

    @include after($p768) {
      width: 50%;
      height: auto;
    }

    @include after($p768) {
      padding: 0 20px;
      font-size: 19px;
      line-height: 26px;
      width: 50%;
    }
  }

  &__picture {
    display: block;
    position: absolute;
    width: 680px;
    height: auto;

    @include before($p768) {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    @include after($p1700) {
      width: auto;
    }

    &.-img1 {
      margin-left: 22px;
      margin-top: 30px;

      @include after($p768) {
        top: -189px;
        right: -210px;
        margin-left: 0;
        margin-top: 0;
      }

      @include after($p1700) {
        top: -315px;
        right: -350px;
      }
    }

    &.-img2 {
      @include after($p768) {
        top: -195px;
        left: -159px;
      }

      @include after($p1700) {
        top: -325px;
        left: -265px;
      }
    }
  }
}
