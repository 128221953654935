@import "../../stylemain/descriptions";

.wallet {
  &__body {
    max-width: 1230px;
  }

  &-table {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: calc(40% - 20px) 60%;
    margin-bottom: 40px;

    @include after($p580) {
      grid-template-columns: calc(26% - 20px) 74%;
    }

    @include after($p1024) {
      margin-bottom: 60px;
    }

    @include after($p1700) {
      row-gap: 30px;
    }

    &__rowtitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }

    &__items {
      max-width: 460px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;

      @include after($p580) {
        display: grid;
        column-gap: 20px;
        grid-template-columns: 1fr 1.67fr;
      }
    }

    &.-header {
      display: none;

      @include after($p580) {
        display: grid;
        margin-bottom: 30px;
      }

      .wallet-table__items {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #a0a0a0;
      }
    }
  }

  &__history {
    margin-top: 80px;

    @include after($p1700) {
      margin-top: 114px;
    }
  }

  .headline {
    margin-bottom: 32px;
  }
}
