@import "../../stylemain/descriptions";

.payment-operations {
  &__top {
    margin-bottom: 50px;

    @include after($p580) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p580) {
      margin-right: 60px;
      margin-bottom: 0;
    }

    @include after($p1700) {
      font-size: 38px;
      line-height: 40px;
    }
  }

  &-list {
    padding: 6px 0 20px 0;

    @include after($p1024) {
      display: grid;
      column-gap: 20px;
      grid-template-columns: calc(13% - 20px) calc(13% - 20px) calc(37% - 10px) calc(
          37% - 10px
        );
    }

    &.-header {
      display: none;

      @include after($p1024) {
        display: grid;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #a0a0a0;
        padding-bottom: 18px;
      }
    }

    &.-items {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      border-bottom: 1px solid #c8c8c8;
    }

    &__type {
      display: inline-block;
      font-size: 10px;
      line-height: 16px;
      color: #000000;
      padding: 0 6px;
      background: #52f6fc;
    }

    &__nowrap {
      position: absolute;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 10px;

        @include after($p1024) {
          margin-bottom: 0;
        }
      }

      @include after($p1024) {
        display: block;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #a0a0a0;
      width: 70px;

      @include after($p1024) {
        display: none;
      }
    }

    &__value {
      position: relative;
      width: calc(100% - 70px);

      @include after($p1024) {
        width: auto;
      }

      &.-copy {
        padding-right: 25px;

        .payment-operations-list__nowrap {
          max-width: calc(100% - 18px);
        }
      }
    }
  }

  &__buttonrefresh {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    box-sizing: border-box;
    outline: 0;
    padding: 0 0 0 25px;
    margin: 9px 0 0 0;
    border: 0 none;
    background: none;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .copy-text {
    position: absolute;
    top: 2px;
    right: 0;
  }
}
