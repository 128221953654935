@import "../../../stylemain/descriptions";

.scheme-work {
  margin-bottom: 100px;

  @include after($p768) {
    margin-bottom: 153px;
  }

  @include after($p1700) {
    margin-bottom: 255px;
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 30px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 24px;
    }

    @include after($p1700) {
      font-size: 64px;
      line-height: 67px;
      margin-bottom: 43px;
    }
  }

  &__body {
    @include after($p768) {
      position: relative;
      height: 405px;
    }

    @include after($p1024) {
      height: 540px;
    }

    @include after($p1700) {
      height: 898px;
    }
  }

  &__img {
    display: none;

    @include after($p768) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 757px;
      height: auto;
    }

    @include after($p1024) {
      width: 1009px;
    }

    @include after($p1700) {
      width: 1682px;
    }
  }

  &__listitem {
    position: relative;
    padding-left: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;

    @include after($p768) {
      position: absolute;
      padding-left: 0;
    }

    @include after($p1024) {
      font-weight: 800;
      font-size: 24px;
      line-height: 26px;
    }

    @include after($p1700) {
      font-size: 40px;
      line-height: 42px;
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &.-step1 {
      color: #52f6fc;

      @include after($p768) {
        top: 54px;
        left: 124px;
      }

      @include after($p1024) {
        top: 68px;
        left: 161px;
      }

      @include after($p1700) {
        top: 114px;
        left: 269px;
      }

      svg {
        @include after($p768) {
          top: auto;
          bottom: -19px;
          left: -7px;
        }

        @include after($p1024) {
          bottom: -24px;
          left: -9px;
        }

        @include after($p1700) {
          bottom: -42px;
          left: -15px;
        }
      }
    }

    &.-step2 {
      color: #fa1af7;

      @include after($p768) {
        top: 54px;
        left: 497px;
      }

      @include after($p1024) {
        top: 68px;
        left: 655px;
      }

      @include after($p1700) {
        top: 114px;
        left: 1092px;
      }

      svg {
        @include after($p768) {
          top: -7px;
          left: -24px;
        }

        @include after($p1024) {
          top: -5px;
        }

        @include after($p1700) {
          top: -11px;
          left: -56px;
        }
      }
    }

    &.-step3 {
      color: #fa1af7;

      @include after($p768) {
        top: 146px;
        left: 124px;
      }

      @include after($p1024) {
        top: 187px;
        left: 161px;
      }

      @include after($p1700) {
        top: 312px;
        left: 269px;
      }

      svg {
        @include after($p768) {
          top: auto;
          bottom: -8px;
          left: -27px;
        }

        @include after($p1024) {
          bottom: -10px;
        }

        @include after($p1700) {
          bottom: -19px;
          left: -52px;
        }
      }
    }

    &.-step4 {
      color: #52f6fc;

      @include after($p768) {
        top: 146px;
        left: 497px;
      }

      @include after($p1024) {
        top: 187px;
        left: 655px;
      }

      @include after($p1700) {
        top: 312px;
        left: 1092px;
      }

      svg {
        @include after($p768) {
          top: auto;
          bottom: 4px;
          left: -27px;
        }

        @include after($p1024) {
          bottom: 2px;
          left: -32px;
        }

        @include after($p1700) {
          bottom: 0;
          left: -67px;
        }
      }
    }

    &.-step5 {
      color: #52f6fc;

      @include after($p768) {
        top: 246px;
        left: 124px;
      }

      @include after($p1024) {
        top: 319px;
        left: 161px;
      }

      @include after($p1700) {
        top: 532px;
        left: 269px;
      }

      svg {
        @include after($p768) {
          top: 6px;
          left: -29px;
        }

        @include after($p1024) {
          top: 19px;
          left: -31px;
        }

        @include after($p1700) {
          top: 19px;
          left: -62px;
        }
      }
    }

    &.-step6 {
      color: #fa1af7;

      @include after($p768) {
        top: 246px;
        left: 497px;
      }

      @include after($p1024) {
        top: 319px;
        left: 655px;
      }

      @include after($p1700) {
        top: 532px;
        left: 1092px;
      }

      svg {
        @include after($p768) {
          top: 24px;
          left: -32px;
        }

        @include after($p1024) {
          top: 41px;
        }

        @include after($p1700) {
          top: 66px;
          left: -70px;
        }
      }
    }

    svg {
      position: absolute;
      top: 3px;
      left: 0;
      width: 12px;
      height: auto;
      stroke: currentcolor;

      @include after($p1024) {
        width: 15px;
      }

      @include after($p1700) {
        width: 30px;
      }
    }

    br {
      @include before($p768) {
        display: none;
      }
    }
  }

  &__note {
    margin-top: 30px;
    padding: 20px;
    max-width: 330px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 24px;

    @include after($p768) {
      position: absolute;
      bottom: -38px;
      right: 20px;
      max-width: inherit;
    }

    @include after($p1024) {
      bottom: -52px;
      right: 28px;
      margin-top: 0;
      padding: 27px 27px 27px 24px;
    }

    @include after($p1700) {
      bottom: -86px;
      right: 47px;
      padding: 46px 46px 46px 40px;
      border-radius: 40px;
    }
  }

  &__noteitem {
    position: relative;
    padding-left: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #0c0d2b;

    @include after($p1700) {
      font-size: 24px;
      line-height: 30px;
      padding-left: 54px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      @include after($p1700) {
        margin-bottom: 20px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 100%;

      @include after($p1700) {
        width: 30px;
        height: 30px;
      }
    }

    &:nth-child(odd):before {
      background-color: #eb19ea;
    }

    &:nth-child(even):before {
      background-color: #52f6fc;
    }
  }

  .button {
    width: 100%;
    max-width: 350px;
    margin-top: 40px;

    @include after($p768) {
      max-width: inherit;
      width: 330px;
      margin-top: 0;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    @include after($p1024) {
      width: 367px;
    }

    @include after($p1700) {
      width: 611px;
    }
  }
}
