@import "../../../stylemain/descriptions";

.project-card-documentation {
  margin-bottom: 70px;

  @include after($p1700) {
    margin-bottom: 130px;
  }

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 65px;
    }
  }

  &__list {
    @include after($p580) {
      display: flex;
      justify-content: space-between;
      max-width: 950px;
    }
  }

  &__item {
    display: block;
    font-weight: 300;
    font-size: calc(16px + 8 * ((100vw - 320px) / 1600));
    line-height: calc(22px + 11 * ((100vw - 320px) / 1600));
    color: #7d7d7d;
    text-decoration: none;

    &:not(:last-child) {
      @include before($p580) {
        margin-bottom: 40px;
      }
    }

    @include after($p580) {
      width: 42%;
    }

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &__itemtitle {
    font-weight: 700;
    color: #000000;
    margin-bottom: 8px;

    @include after($p1700) {
      margin-bottom: 30px;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 400px;
    margin-bottom: 20px;

    @include after($p1700) {
      margin-bottom: 36px;
    }
  }
}
