@import "../../../stylemain/descriptions";

.info {
  margin-bottom: 100px;

  @include after($p1700) {
    margin-bottom: 169px;
  }

  &__body {
    padding: 20px;
    background: #5e07dd;
    border-radius: 24px;

    @include after($p768) {
      padding: 30px 50px;
    }

    @include after($p1700) {
      padding: 50px 74px;
    }

    @include after($p1700) {
      padding: 85px 123px;
      border-radius: 40px;
    }
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
    max-width: 400px;

    @include after($p768) {
      max-width: 56%;
    }

    @include after($p1700) {
      font-size: 48px;
      line-height: 50px;
      margin-bottom: 40px;
    }
  }

  &__text {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    max-width: 300px;

    @include after($p768) {
      max-width: 39%;
      font-size: 19px;
      line-height: 20px;
    }

    @include after($p1700) {
      font-size: 32px;
      line-height: 34px;
    }
  }

  &__img {
    display: block;

    @include after($p768) {
      margin-top: -20px;
    }

    @include after($p1024) {
      margin-top: -30px;
    }

    @include after($p1700) {
      margin-top: -52px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
