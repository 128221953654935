@import "../../../stylemain/descriptions";

.project-card-button-invest {
  margin-bottom: 70px;

  @include after($p1700) {
    margin-bottom: 130px;
  }

  .button.-size-l {
    width: 100%;

    @include after($p768) {
      height: 77px;
      line-height: 77px;
    }

    @include after($p1700) {
      line-height: 180px;
      height: 180px;
    }
  }
}
