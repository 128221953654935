@import "../../../stylemain/descriptions";

.project-card-info {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 50px;
  margin-bottom: 70px;

  @include after($p1024) {
    grid-template-columns: 1.68fr 1fr;
    column-gap: 10%;
  }

  @include after($p1700) {
    grid-template-columns: 1.68fr 1.17fr 1fr;
    column-gap: 8%;
    margin-bottom: 130px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;

    @include after($p768) {
      grid-template-columns: 49% 51%;
      row-gap: 24px;
    }
  }

  &__itemname {
    font-weight: 700;
    color: #a6a6a6;
    font-size: calc(14px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(20px + 4 * ((100vw - 320px) / 1600));

    &:not(:first-child) {
      margin-top: 16px;

      @include after($p768) {
        margin-top: 0;
      }
    }

    @include after($p1700) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__itemvalue {
    color: #000000;
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(22px + 4 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__descr {
    color: #000000;
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(22px + 4 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__descrlogo {
    display: block;
    width: 100%;
    height: auto;
    max-width: 258px;
    margin-top: 24px;

    @include after($p1700) {
      margin-top: 36px;
    }
  }

  &__costtitle {
    font-weight: 700;
    color: #a6a6a6;
    font-size: calc(14px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(20px + 4 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__costvalue {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 800;
    color: #000000;
    font-size: calc(34px + 14 * ((100vw - 320px) / 1600));
    line-height: calc(36px + 14 * ((100vw - 320px) / 1600));
    margin-top: 3px;

    @include after($p1700) {
      font-size: 48px;
      line-height: 50px;
    }
  }

  &__costvaluesumm {
    margin-right: 30px;
  }

  &__costinvest {
    margin-top: 24px;

    @include after($p1700) {
      margin-top: 30px;
    }

    .button {
      width: 100%;
      max-width: 335px;
    }
  }

  &__costcalculate {
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    text-decoration: none;
    font-family: $font-family-Manrope;
    padding: 0;
    outline: 0;
    cursor: pointer;
    border: 0 none;
    background: none;
    margin-top: 30px;

    @include after($p1700) {
      margin-top: 48px;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background: #5e07dd;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:after {
          display: none;
        }
      }
    }
  }

  &-progress {
    max-width: 396px;
    margin-bottom: 50px;

    @include after($p1700) {
      margin-bottom: 70px;
    }

    &__title {
      font-weight: 700;
      color: #a6a6a6;
      font-size: calc(14px + 4 * ((100vw - 320px) / 1600));
      line-height: calc(20px + 4 * ((100vw - 320px) / 1600));

      @include after($p1700) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__values {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      color: #a6a6a6;
      font-size: calc(14px + 4 * ((100vw - 320px) / 1600));
      line-height: calc(20px + 4 * ((100vw - 320px) / 1600));
      text-transform: uppercase;
      margin-top: 20px;

      @include after($p1700) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__valueamount {
      color: #5e07dd;
    }

    &__bar {
      position: relative;
      margin-top: 20px;
      background: #e6e3ea;
      box-shadow: 0px 1px 34px rgba(0, 0, 0, 0.43);
      border-radius: 20px;
      height: 24px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 25%;
        width: 1px;
        background: #cdcad2;
        z-index: 0;
      }
    }

    &__barinner {
      height: 24px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #cdcad2;
        z-index: 0;
      }

      &:before {
        left: 50%;
      }

      &:after {
        left: 75%;
      }

      div {
        position: relative;
        background: #5e07dd;
        box-shadow: 0px 1px 34px #8135f0;
        border-radius: 20px;
        height: 24px;
        z-index: 1;
      }
    }
  }

  .-text-uppercase {
    text-transform: uppercase;
  }
}
