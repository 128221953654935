@import "../../stylemain/descriptions";

.text-input {
  &__field {
    width: 100%;
    display: inline-block;
    font-family: $font-family-Manrope;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3f3f3f;
    padding: 14px 20px;
    box-sizing: border-box;
    outline: 0;
    text-decoration: none;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;

    @include placeholder(#a0a0a0);

    &:focus {
      @include placeholder(transparent);
    }

    &:disabled {
      opacity: 0.5;
      background: #a0a0a033;
      border-color: #a0a0a033;
    }

    &.-error {
      border-color: #dc143c;
    }
  }

  &__message {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 4px;
  }
}
