@import "../../../stylemain/descriptions";

.project-card-other {
  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 65px;
    }
  }
}
