@import "../../stylemain/descriptions";

.header-navigate {
  &__frame {
    &.-main {
      @include before($p768) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #0c0d2b;
        z-index: 0;
      }
    }

    &.-admin {
      @include before($p1024) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #ffffff;
        z-index: 0;
      }
    }
  }

  &__door {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: none;
    border: 0 none;
    padding: 0;
    margin: 0 0 0 auto;
    outline: none;
    cursor: pointer;
    z-index: 1;

    svg {
      fill: currentColor;

      &.-close {
        display: none;
      }
    }

    &.-main {
      color: #ffffff;

      @include after($p768) {
        display: none;
      }
    }

    &.-admin {
      color: inherit;

      @include after($p1024) {
        display: none;
      }
    }
  }

  &.-open {
    color: #000000;

    .header-navigate__door {
      svg.-close {
        display: block;
      }

      svg.-burger {
        display: none;
      }
    }

    .header-navigate__frame {
      display: block;
    }
  }
}
