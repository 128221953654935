@import "../../../stylemain/descriptions";

.start {
  position: relative;
  background: url("./start-bg.jpg") no-repeat 0 0;
  background-size: cover;

  @include after($p768) {
    min-height: 100vh;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: url("./start-bg-bottom.png") repeat-x 50% 0;
    background-size: auto 100%;
    z-index: 1;

    @include after($p1700) {
      height: 228px;
    }
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
  }

  &__body {
    position: relative;
    padding-top: 100px;
    padding-bottom: 110px;
    z-index: 2;

    @include after($p768) {
      display: flex;
      padding-top: 20vh;
      padding-bottom: 0;
    }

    @include after($p1024) {
      justify-content: flex-start;
    }
  }

  &__content {
    max-width: 500px;

    @include after($p768) {
      width: 435px;
      max-width: inherit;
    }

    @include after($p1024) {
      width: 45%;
    }

    .button {
      width: 100%;
      max-width: 350px;
      margin-top: 30px;

      @include after($p768) {
        width: 330px;
        max-width: inherit;
        margin-top: 60px;
      }

      @include after($p1024) {
        width: 367px;
      }

      @include after($p1700) {
        width: 611px;
        margin-top: 98px;
      }
    }
  }

  &__extra {
    margin-top: 60px;

    @include after($p768) {
      margin-left: 80px;
      margin-top: 0;
    }

    @include after($p1024) {
      margin-left: 15%;
      margin-right: 0;
    }
  }

  &__extratext {
    color: #52f6fc;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    @include after($p768) {
      font-size: 20px;
      line-height: 24px;
    }

    @include after($p1024) {
      font-size: 24px;
      line-height: 26px;
    }

    @include after($p1700) {
      font-size: 40px;
      line-height: 42px;
    }
  }

  &__technology {
    display: flex;
    align-items: center;
    color: #e6dfec;
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;

    @include after($p768) {
      font-size: 15px;
      line-height: 20px;
      margin-top: 132px;
    }

    @include after($p1700) {
      font-size: 25px;
      line-height: 34px;
      margin-top: 220px;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  &__technologyicon {
    position: relative;
    z-index: 0;
    margin: 0 8px;

    @include after($p1700) {
      margin: 0 14px;
    }

    &:before {
      @include after($p768) {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 88px;
        height: 90px;
        background: url("./start-technology-bg.png") no-repeat 0 0;
        background-size: cover;
      }

      @include after($p1700) {
        width: 220px;
        height: 225px;
      }
    }

    img {
      position: relative;
      display: block;
      width: 29px;
      height: auto;

      @include after($p1700) {
        width: auto;
      }
    }
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.02em;
    margin-bottom: 18px;

    @include after($p768) {
      font-size: 48px;
      line-height: 50px;
      margin-bottom: 24px;
    }

    @include after($p1024) {
      font-size: 52px;
      line-height: 55px;
    }

    @include after($p1700) {
      font-size: 86px;
      line-height: 92px;
      margin-bottom: 42px;
    }
  }

  &__text {
    max-width: 86%;
  }
}
