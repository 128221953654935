@import "../../stylemain/descriptions";

.tabs {
  padding-bottom: 20px;
  border-bottom: 1px solid #d5d5d5;
  margin-top: 40px;
  margin-bottom: 40px;

  @include after($p768) {
    display: flex;
  }

  @include after($p1700) {
    padding-bottom: 23px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;

      @include after($p768) {
        margin-right: 32px;
        margin-bottom: 0;
      }

      @include after($p1700) {
        margin-right: 40px;
      }
    }

    button {
      position: relative;
      display: inline-block;
      padding: 0;
      margin: 0;
      outline: none;
      background: none;
      border: 0 none;
      font-weight: 800;
      color: #0c0d2b;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.02em;
      cursor: pointer;

      @include after($p1700) {
        font-size: 20px;
        line-height: 22px;
      }

      &:after {
        display: none;
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background: #5e07dd;

        @include after($p768) {
          bottom: -21px;
        }

        @include after($p1700) {
          bottom: -27px;
        }
      }
    }

    &.-active {
      button {
        cursor: default;

        &:after {
          display: block;
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        button:after {
          display: block;
        }
      }
    }
  }
}
