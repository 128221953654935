@import "../../../stylemain/descriptions";

.payment-balances {
  max-width: 810px;

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 24px;

    @include after($p1700) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 36px;
    }
  }

  &__text {
    color: #7c7c7c;
    font-size: calc(14px + 6 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 9 * ((100vw - 320px) / 1600));
    margin-bottom: 40px;

    @include after($p1700) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__steps {
    color: #7d7d7d;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 40px;

    @include after($p1700) {
      margin-bottom: 65px;
    }

    span {
      position: relative;
      display: inline-block;

      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
      }

      &:before {
        content: "";
        width: 100%;
        background: rgba(94, 7, 221, 0.2);
      }

      &:after {
        width: 50%;
        background: #5e07dd;
      }
    }

    &.-finish span {
      &:after {
        width: 100%;
      }
    }
  }

  &__note {
    margin-bottom: 35px;

    li {
      position: relative;
      color: #7c7c7c;
      padding-left: 24px;
      font-size: calc(12px + 4 * ((100vw - 320px) / 1600));
      line-height: calc(16px + 8 * ((100vw - 320px) / 1600));
      margin-bottom: 10px;

      @include after($p1700) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &.-highlighted {
        color: #ff0000;
      }

      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 12px;
        width: 4px;
        height: 4px;
        background: #ff0000;
        border-radius: 100%;

        @include after($p1700) {
          top: 11px;
        }
      }
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 20% 80%;
    row-gap: 8px;
    color: #000000;
    font-size: calc(12px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 8 * ((100vw - 320px) / 1600));
    margin-bottom: 60px;

    @include after($p1700) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__infolabel {
    font-weight: 700;
  }

  &__infoaddress {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include after($p480) {
      width: auto;
      max-width: 100%;
    }
  }

  &__fieldcopy {
    position: relative;
    display: inline-block;
    padding-right: 25px;

    .copy-text {
      position: absolute;
      top: 0;
      right: 0;

      @include after($p1700) {
        top: 3px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
