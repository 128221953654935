@import "../../../stylemain/descriptions";

.project-card-media {
  padding: 16px 0 16px 0;
  background: #232323;
  margin-bottom: 54px;

  @include after($p580) {
    padding: 24px 0 32px 0;
  }

  @include after($p1700) {
    padding: 40px 0 54px 0;
    margin-bottom: 90px;
  }

  &__box {
    max-width: 320px;
    margin: 0 auto;

    @include after($p480) {
      max-width: 440px;
    }

    @include after($p580) {
      max-width: 540px;
    }

    @include after($p768) {
      max-width: 660px;
    }

    @include after($p1700) {
      max-width: 1092px;
    }
  }

  &__item {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 32px;

    @include after($p480) {
      height: 224px;
      padding: 0 32px;
    }

    @include after($p580) {
      height: 280px;
    }

    @include after($p768) {
      height: 305px;
      padding: 0 60px;
    }

    @include after($p1700) {
      padding: 0 96px;
      height: 509px;
    }

    img,
    video {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;

    @include after($p480) {
      display: block;
      width: 32px;
      height: 32px;
      background: url("./arrow.svg") no-repeat 100% 50%;
      background-repeat: no-repeat;
    }

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
    transform: rotate(-180deg);
  }

  .swiper-pagination {
    bottom: 0;

    @include after($p480) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: #ffffff;
  }
}
