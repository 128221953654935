@import "../../stylemain/descriptions";

.header {
  position: relative;
  z-index: 10;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #d5d5d5;

    @include after($p1024) {
      height: 80px;
    }
  }

  &__logo {
    position: relative;
    z-index: 1;
    height: 60px;
    width: auto;
    fill: currentColor;

    @include after($p1024) {
      height: auto;
    }
  }

  &__nav {
    @include after($p1024) {
      width: 70%;
    }
  }

  &__menu {
    @include after($p1024) {
      display: flex;
      align-items: center;
    }
  }

  &__menuitem {
    margin-bottom: 18px;
    text-align: center;

    @include after($p1024) {
      margin: 0 12px;
      text-align: left;
    }

    @include after($p1700) {
      margin: 0 30px;
    }
  }

  &__links {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: inherit;
    text-align: center;

    @include after($p1024) {
      display: flex;
      align-items: center;
      text-align: left;
    }

    a,
    button {
      @include after($p1024) {
        padding-left: 24px !important;
      }
    }
  }

  &__linksitem {
    margin-bottom: 18px;

    @include after($p1024) {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      @include after($p1024) {
        margin-right: 20px;
      }

      @include after($p1700) {
        margin-right: 60px;
      }
    }
  }

  &__linksicon {
    display: none;

    @include after($p1024) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14px;
      height: 14px;
    }
  }

  a,
  .header-navigate__frame button {
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: inherit;
    text-decoration: none;
    font-family: $font-family-Manrope;
    padding: 0;
    outline: 0;
    cursor: pointer;
    border: 0 none;
    background: none;

    &:after {
      display: none;
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background: #5e07dd;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:after {
          display: block;
        }
      }
    }

    &.-active {
      &:after {
        display: block;
      }
    }
  }

  .header-navigate__frame {
    padding-top: 80px;

    @include after($p1024) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0;
    }
  }

  &.-dark {
    color: #ffffff;
    background: #232323;

    .header__body {
      border-color: #3c3c3c;
    }
  }
}

.-header-open {
  .header__logo {
    fill: #000000;
  }
}
