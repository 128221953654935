@import "./stylemain/descriptions";

.page {
  position: relative;
  overflow: hidden;

  &.-index {
    color: #d6d7ea;
    background: #0c0d2b;
  }

  &.-admin {
    display: grid;
    grid-template-rows: 60px auto auto;
    min-height: 100vh;

    @include after($p1024) {
      grid-template-rows: 80px auto 80px;
    }
  }
}
