@import "../../../../stylemain/descriptions";

.notification-subscribe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 30px;
  text-align: center;
  color: #0c0d2b;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 0px 30px rgba(189, 3, 254, 0.7);
  z-index: 1000;

  @include after($p1700) {
    padding: 56px 40px;
    max-width: 780px;
  }

  &__body {
    max-width: 450px;
    margin: 0 auto;
  }

  &__title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 800;
    letter-spacing: -0.02em;
    color: #0c0d2b;

    @include after($p1700) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 18px;
    margin-top: 12px;
  }

  &__button {
    margin-top: 25px;

    .button {
      min-width: 182px;
    }
  }

  &__icon {
    display: block;
    width: 80px;
    height: auto;
    margin: 0 auto 25px auto;

    @include after($p1700) {
      width: auto;
    }
  }
}
