@import "../../stylemain/descriptions";

.payment {
  &__body {
    max-width: 1230px;
  }

  &__project {
    margin-bottom: 50px;
  }

  &__main {
    @include after($p768) {
      display: grid;
      grid-template-columns: 1.68fr 1fr;
      column-gap: 13%;
      align-items: flex-start;
    }
  }

  &__history {
    margin-top: 80px;

    @include after($p1700) {
      margin-top: 114px;
    }
  }

  .headline {
    margin-bottom: 32px;
  }
}
