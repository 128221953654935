@import "../../stylemain/descriptions";

.action-plus-minus {
  display: flex;
  align-items: center;

  &__button {
    display: block;
    box-sizing: border-box;
    outline: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0 none;
    background: none;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:disabled {
      cursor: default;

      svg {
        fill: #a0a0a0;
      }
    }

    svg {
      width: 36px;
      height: 36px;
      fill: #5e07dd;

      @include after($p1700) {
        width: auto;
        height: auto;
      }
    }
  }
}
