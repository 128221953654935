@import "../../stylemain/descriptions";

.button {
  display: inline-block;
  font-family: $font-family-Manrope;
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  border: 0 none;
  background: none;

  &.-size-l {
    font-weight: 800;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding: 0 40px;
    border-radius: 24px;

    @include after($p768) {
      font-size: 22px;
      height: 64px;
      line-height: 62px;
    }

    @include after($p1700) {
      font-size: 36px;
      line-height: 107px;
      height: 107px;
      padding: 0 70px;
      border-radius: 30px;
    }
  }

  &.-size-m {
    font-weight: 700;
    font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    line-height: calc(40px + 20 * ((100vw - 320px) / 1600));
    height: calc(40px + 20 * ((100vw - 320px) / 1600));
    padding: 0 calc(40px + 10 * ((100vw - 320px) / 1600));
    border-radius: calc(16px + 4 * ((100vw - 320px) / 1600));

    @include after($p1700) {
      font-size: 20px;
      line-height: 60px;
      height: 60px;
      padding: 0 50px;
      border-radius: 20px;
    }
  }

  &.-size-s {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    padding: 0 30px;
    border-radius: 15px;
  }

  &.-blue {
    color: #0c0d2b;
    background: #52f6fc;
    box-shadow: 0px 1px 34px rgba(82, 246, 252, 0.5);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #54e2e8;
      }
    }
  }

  &.-purpule {
    color: #ffffff;
    background: #5e07dd;
    box-shadow: 0px 1px 34px #8135f0;
  }

  &.-transparent {
    color: #3f3f3f;
  }

  &:disabled {
    color: #d9d9d9;
    background: #a0a0a0;
    box-shadow: none;
    cursor: default;
  }
}
