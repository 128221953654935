@import "../../../stylemain/descriptions";

.footer-main {
  padding: 40px 0;
  background: #080823;

  @include after($p1024) {
    padding: 60px 0;
  }

  @include after($p1700) {
    padding: 100px 0;
  }

  &__top {
    @include after($p768) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo {
    display: block;
    width: 152px;
    height: auto;
    margin: 0 auto 20px auto;

    @include after($p768) {
      margin: 0 0 48px 0;
    }

    @include after($p1700) {
      width: auto;
      margin-bottom: 74px;
    }
  }

  &__info {
    color: #75758a;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px;

    @include after($p768) {
      text-align: left;
      margin-bottom: 0;
    }

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &__bottom {
    color: #75758a;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin-top: 50px;

    @include after($p768) {
      text-align: left;
    }

    @include after($p1024) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 126px;
    }

    @include after($p1700) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 206px;
    }
  }

  &__copyright {
    margin-top: 30px;

    @include after($p1024) {
      margin-top: 0;
    }
  }

  a {
    color: #ffffff;
    text-decoration: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
