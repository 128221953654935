@import "../../stylemain/descriptions";

.notice {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #3f3f3f;
  padding: 20px 24px;
  background: rgba(82, 246, 252, 0.3);

  a {
    color: inherit;
  }
}
