@import "../../stylemain/descriptions";

.feedback {
  &__body {
    max-width: 674px;
  }

  &__field {
    font-size: 0;
    line-height: 0;
  }

  &__button {
    margin-top: 52px;

    .button {
      width: 100%;
      max-width: 395px;
    }
  }

  &__message {
    margin-bottom: 30px;
  }

  .headline {
    margin-bottom: 40px;
  }

  .notice {
    margin-bottom: 30px;
  }
}
