@import "../../../stylemain/descriptions";

.projects-main {
  margin-bottom: 50px;

  @include after($p768) {
    margin-bottom: 115px;
  }

  @include after($p1700) {
    margin-bottom: 192px;
  }

  &__title {
    color: #ffffff;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 54px;
    }

    @include after($p1700) {
      font-size: 64px;
      line-height: 67px;
      margin-bottom: 90px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;

    @include after($p580) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }

    @include after($p768) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
    }

    @include after($p1024) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 30px;
      row-gap: 30px;
    }

    @include after($p1700) {
      column-gap: 40px;
      row-gap: 40px;
    }
  }

  &__item {
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    color: #d6d7ea;

    @include after($p580) {
      max-width: 250px;
    }

    @include after($p768) {
      max-width: 340px;
    }

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &__img {
    background: #110d31;
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.35);
    border-radius: 24px;

    @include after($p1700) {
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.35);
      border-radius: 40px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 24px;

      @include after($p1700) {
        border-radius: 40px;
      }
    }
  }

  &__subtitle {
    color: #52f6fc;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-top: 33px;

    @include after($p1024) {
      font-size: 24px;
      line-height: 26px;
    }

    @include after($p1700) {
      font-size: 40px;
      line-height: 42px;
      margin-top: 55px;
    }
  }

  &__info {
    margin-top: 20px;

    @include after($p1700) {
      margin-top: 35px;
    }

    span {
      text-transform: uppercase;
    }
  }

  &__price {
    font-weight: 700;
    margin-top: 26px;

    @include after($p1700) {
      margin-top: 44px;
    }

    span {
      text-transform: uppercase;
    }
  }

  &__button {
    margin-top: 55px;
    text-align: center;

    @include after($p768) {
      text-align: left;
    }
  }
}
