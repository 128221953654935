@import "../../stylemain/descriptions";

.timer {
  color: #7c7c7c;
  font-size: calc(14px + 6 * ((100vw - 320px) / 1600));
  line-height: calc(16px + 9 * ((100vw - 320px) / 1600));

  @include after($p768) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @include after($p1700) {
    font-size: 20px;
    line-height: 27px;
  }

  &__time {
    font-weight: 700;
  }

  &__value {
    &.-highlighted {
      color: #ff0000;
    }
  }

  &__message {
    margin-top: 10px;
    font-size: calc(12px + 4 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 8 * ((100vw - 320px) / 1600));

    @include after($p768) {
      width: 66%;
      margin-top: 0;
    }

    @include after($p1700) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
