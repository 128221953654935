@import "../../../stylemain/descriptions";

.project-card-team {
  margin-bottom: 70px;

  @include after($p1700) {
    margin-bottom: 130px;
  }

  &__title {
    color: #0c0d2b;
    font-weight: 800;
    font-size: calc(28px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(30px + 10 * ((100vw - 320px) / 1600));
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    @include after($p768) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 65px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;

    @include after($p768) {
      grid-template-columns: 1fr 1fr;
      column-gap: 8%;
    }

    @include after($p1700) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 30px;
    }
  }

  &__item {
    font-weight: 300;
    font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 11 * ((100vw - 320px) / 1600));
    color: #000000;

    @include after($p1700) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__itemname {
    font-weight: 300;
    font-size: calc(14px + 10 * ((100vw - 320px) / 1600));
    line-height: calc(16px + 17 * ((100vw - 320px) / 1600));
    color: #7d7d7d;
    margin-bottom: 16px;

    @include after($p1700) {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 24px;
    }

    b {
      font-weight: 700;
      font-size: calc(18px + 14 * ((100vw - 320px) / 1600));
      line-height: calc(22px + 22 * ((100vw - 320px) / 1600));
      color: #000000;

      @include after($p1700) {
        font-size: 32px;
        line-height: 44px;
      }
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 200px;
    margin-bottom: 16px;

    @include after($p1700) {
      max-width: 250px;
      margin-bottom: 35px;
    }
  }
}
