@import "../../stylemain/descriptions";

.projects-table {
  display: grid;
  grid-template-columns: 100%;

  align-items: center;
  padding: 0 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 24px;

  @include after($p480) {
    grid-template-columns: 60% 40%;
  }

  @include after($p580) {
    grid-template-columns: 52% 24% 24%;
  }

  @include after($p768) {
    grid-template-columns: 36% 16% 16% 16% 16%;
  }

  @include after($p1024) {
    grid-template-columns: 32% 15% 15% 15% 15% 8%;
  }

  @include after($p1700) {
    font-size: 20px;
    line-height: 18px;
  }

  &.-items {
    padding-top: 14px;
    padding-bottom: 14px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 9px;
  }

  &.-bg-with-hover {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &.-bg {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &__name {
    color: #000000;
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;

    @include after($p1700) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &__price {
    text-transform: uppercase;
    color: #5e07dd;
    margin-top: 3px;

    @include after($p1700) {
      margin-top: 8px;
    }
  }

  &__item {
    display: none;

    &:nth-child(1) {
      display: block;
    }

    &:nth-child(2) {
      @include after($p480) {
        display: block;
      }
    }

    &:nth-child(3) {
      @include after($p580) {
        display: block;
      }
    }

    &:nth-child(4),
    &:nth-child(5) {
      @include after($p768) {
        display: block;
      }
    }

    @include after($p1024) {
      display: block;
    }

    &.-cost {
      text-transform: uppercase;
      white-space: nowrap;
    }

    &.-header {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #a0a0a0;
    }
  }

  &__descr {
    @include after($p580) {
      display: flex;
      align-items: center;
    }
  }

  &__img {
    width: 95px;

    @include after($p1700) {
      margin-right: 30px;
    }

    img {
      display: block;
      width: 85px;
      height: auto;
      border-radius: 10px;
    }
  }

  &__info {
    margin-top: 10px;

    @include after($p580) {
      margin-top: 0;
      width: calc(100% - 95px);
    }

    @include after($p1700) {
      width: calc(100% - 115px);
    }
  }

  &__percent {
    position: relative;
    padding-left: 15px;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &-text-overflow {
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
