@import "../../stylemain/descriptions";

.profile {
  &__body {
    max-width: 560px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  &__rows {
    color: #000000;

    @include after($p768) {
      display: grid;
      grid-template-columns: 46% 54%;
      row-gap: 30px;
      align-items: center;
    }
  }

  &__field {
    margin-bottom: 32px;

    @include after($p768) {
      margin-bottom: 0;
    }
  }

  &__fiedtext {
    max-width: 280px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include after($p768) {
      padding-left: 20px;
    }
  }

  &__fiedname {
    font-weight: 700;
    margin-bottom: 12px;

    @include after($p768) {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    margin-top: 30px;
  }

  &__button {
    margin-top: 52px;

    .button {
      width: 100%;
      max-width: 395px;
    }
  }

  &__message {
    margin-bottom: 30px;
  }

  &__address {
    position: relative;
    display: inline-block;
    padding-right: 25px;

    .copy-text {
      position: absolute;
      top: 2px;
      right: 0;
    }
  }
}
